import styled from 'styled-components'

export const NotificationMultipleBannerContainer = styled.div`
  && {
    div [aria-controls^='banner-item-'] > div:nth-of-type(2) > div {
      display: grid;
      grid-gap: 25px;
      justify-content: space-between;
      grid-template-columns: 4fr fit-content(120px);
      grid-template-rows: auto;
      grid-template-areas: 'title date';

      @media (max-width: ${(props) =>
          (props.theme &&
            props.theme.breakPoints &&
            props.theme.breakPoints.md) ||
          '600px'}) {
        grid-gap: 7px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
          'title'
          'date';

        span:nth-of-type(1) {
          font-size: 16px;
        }

        span:nth-of-type(2) {
          border-left: 1px;
          font-size: 12px;
        }
      }

      span:nth-of-type(1) {
        padding: 0 12px 0 0;
        font-size: 16px;
        display: flex;
        align-items: center;
      }

      span:nth-of-type(2) {
        padding-right: 12px;
        float: right;
        font-size: 12px;
      }
    }
  }
`

export const DateWrapper = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
`
