import { interfaces } from '@jarvis/shell-commons';
import { curry, last } from 'ramda';

export const mergePaths = (base, end) => {
  const endTrimmed = end.startsWith('/') ? end.substring(1) : end;
  const finalPath = `${base}${endTrimmed}`;
  return last(finalPath) === '/'
    ? finalPath.substring(0, finalPath.length - 1)
    : finalPath;
};

export const makePathRelative = curry((currentPathData, pathname) => {
  const { country, language } = currentPathData;
  const relPath = mergePaths(`/${country}/${language}/`, pathname);
  return relPath;
});

export const getUrlSearchParams = ({
  navigation
}: {
  navigation:
    | typeof interfaces.v1.navigation
    | {
        location: {
          search: string;
        };
      };
}) => {
  const urlSearchParams = new URLSearchParams(navigation?.location?.search);
  return Object.fromEntries(urlSearchParams?.entries());
};
