import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InboxClient } from '@jarvis/web-stratus-client'
import { NotificationBannerContainer, LoaderContainer } from './styles'
import JarvisBannerNotificationItem from './JarvisBannerNotificationItem'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
import { NotificationError } from '../../utils/errors.tsx'
import { ATLAS_PATH, getPath } from '../../utils/logInfo'
import { checkProps, checkStack } from '../../utils/shared'

const Loader = ({ show }) => {
  return show && <LoaderContainer></LoaderContainer>
}

export const JarvisBannerNotification = ({
  country,
  language,
  authProvider,
  notificationCount,
  showOnData,
  showDebug,
  onError,
  mockJsonData,
  baseURLProvider,
  stack,
  refreshDelay,
  shouldShowAction,
  onClickActionFunction,
  autoRefresh
}) => {
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [loading, setLoading] = useState(true)

  checkProps(baseURLProvider, stack, mockJsonData)
  const inboxCheck = checkStack(baseURLProvider, stack)
  const inboxClient = new InboxClient(inboxCheck, authProvider)
  const isNotificationValid = (data) =>
    data &&
    data.serial &&
    data.uuid &&
    data.message &&
    data.message.title &&
    data.message.body

  const assetsProvider = AssetsProviderFactory.create(language, country)
  const direction = assetsProvider.getDirection()

  async function loadBannerNotifications(notificationCount) {
    let getMessagesParams = { displayMode: 'banner' }
    if (notificationCount) {
      getMessagesParams.count = notificationCount
    }
    let startTime = new Date().getTime()
    try {
      let notifications =
        mockJsonData || (await inboxClient.getMessages(getMessagesParams)).data
      setBannerNotifications(notifications)
    } catch (error) {
      const host = await baseURLProvider(
        inboxClient.apiName,
        inboxClient.apiVersion
      )
      await onError({
        host,
        path: getPath(ATLAS_PATH.GET_MESSAGE, getMessagesParams),
        method: 'GET',
        code: error.status,
        payload: error,
        latency: new Date().getTime() - startTime
      })
      setBannerNotifications(NotificationError(error, country, language))
    }
    setLoading(false)
  }

  let timeoutId = null
  let fetching = false

  function cancelScheduledFetch() {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  async function fetchData() {
    if (fetching) {
      return
    }
    fetching = true
    timeoutId = null
    setLoading(true)
    await loadBannerNotifications(notificationCount)
    timeoutId = setTimeout(fetchData, refreshDelay)
    fetching = false
  }

  useEffect(() => {
    if (autoRefresh) {
      fetchData()
    } else {
      cancelScheduledFetch()
    }
    return () => {
      cancelScheduledFetch()
    }
  }, [autoRefresh])

  return (
    <NotificationBannerContainer
      data-testid="portal-notifications-banner"
      show={showOnData && !bannerNotifications?.length}
      dir={direction}
    >
      {<Loader show={loading} />}
      {bannerNotifications?.map((notification) => {
        return (
          isNotificationValid(notification) && (
            <JarvisBannerNotificationItem
              notification={notification}
              key={notification.uuid}
              showDebug={showDebug}
              shouldShowAction={shouldShowAction}
              onClickActionFunction={onClickActionFunction}
              direction={direction}
            />
          )
        )
      })}
    </NotificationBannerContainer>
  )
}

JarvisBannerNotification.defaultProps = {
  showOnData: false,
  showDebug: false,
  mockJson: null,
  onError: (host, path, method, code, payload, latency) => {
    console.log('OnError Method', {
      host,
      path,
      method,
      code,
      payload,
      latency
    })
  },
  refreshDelay: 300000, // 5 min
  autoRefresh: true
}

JarvisBannerNotification.propTypes = {
  /**
   * the user language in ISO 639-1 format
   */
  language: PropTypes.string.isRequired,
  /**
   * the user country in ISO 3166-1 alpha-2 format
   */
  country: PropTypes.string.isRequired,
  /**
   * a JarvisAuthProvider interface implementation to authenticate atlas inbox API requests
   */
  authProvider: PropTypes.object.isRequired,
  /**
   * maximum notifications to be shown
   */
  notificationCount: PropTypes.number.isRequired,
  /**
   * show banner only when data is retrieved
   */
  showOnData: PropTypes.bool,
  /**
   * show debug error data in notification text when it error occurs
   */
  showDebug: PropTypes.bool,
  /**
   * Delegate function to be called when an error occurs.
   * should expect an object as parameter like the following
   * {
   *   host,
   *   path,
   *   method,
   *   code,
   *   payload,
   *   latency,
   * }
   */
  onError: PropTypes.func,
  /**
   * Optional Mock Data to supply to component
   */
  mockJsonData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Base Url to make api requests to
   */
  baseURLProvider: PropTypes.func,
  /**
   * The current environment stack
   */
  stack: PropTypes.number,
  /**
   * Delegate function that decides when the action component will be rendered
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  shouldShowAction: PropTypes.func.isRequired,
  /**
   * onClick Action function to be fired when action component gets clicked
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  onClickActionFunction: PropTypes.func.isRequired,
  /**
   * Milliseconds to refresh the notifications - if not defined it will use the default value 5 minutes
   */
  refreshDelay: PropTypes.number,
  autoRefresh: PropTypes.bool
}

export default JarvisBannerNotification
