import styled from 'styled-components';
import { xs } from '../../helpers/mixins/mediaQueries';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;

  ${props => props.columnDirectionToMobileScreen
    && xs(`
    flex-direction: column;
    align-items: normal;
    `)}
`;

export default { Container };
