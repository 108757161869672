import { Environments } from '../configs/environments';

type ApiType = {
  apiName: string;
  baseUrl: string;
};

const getBaseUrl = (stackName, apiName, apiVersion) => {
  let apiList: ApiType[] = [];
  const environmentData = Environments[stackName];
  const onboardingBaseUrl = environmentData?.AUTHZ_ONBOARDING_BASE_URL;

  const v1ApiList: ApiType[] = [
    {
      apiName: 'ws-hp.com/devices',
      baseUrl: `${environmentData?.AUTHZ_ONBOARDING_DEVICES_BASE_URL}/v1`
    },
    {
      apiName: 'ws-hp.com/messagesdb',
      baseUrl: `${environmentData?.STRATUS_BASE_URL}/atlas/v1`
    }
  ];

  const v2ApiList: ApiType[] = [
    {
      apiName: 'ws-hp.com/accountmgtsvc',
      baseUrl: `${onboardingBaseUrl}/v2/ecosystem/accountmgtsvc`
    },
    {
      apiName: 'ws-hp.com/uimgtsvc',
      baseUrl: `${onboardingBaseUrl}/v2/ecosystem/uimgtsvc`
    },
    {
      apiName: 'ws-hp.com/programmgtsvc',
      baseUrl: `${onboardingBaseUrl}/v2/ecosystem/programmgtsvc`
    }
  ];

  if (apiVersion === '1') {
    apiList = v1ApiList;
  }

  if (apiVersion === '2') {
    apiList = v2ApiList;
  }

  if (apiList.length) {
    const targetApi = apiList.find((api) => api.apiName === apiName);
    if (targetApi) {
      return targetApi.baseUrl;
    }
  }

  return onboardingBaseUrl;
};

export default getBaseUrl;
