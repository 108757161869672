import Card from '@veneer/core/dist/scripts/card/card'
import { ToastNotificationType } from '@veneer/core/dist/scripts/toast_notification'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import withToast from '@veneer/core/dist/scripts/toast_container/with_toast'
import React, { useEffect } from 'react'
import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  InboxClient,
  GetMessagesConfig,
  BaseURLProvider,
  Stack,
  GetMessageCountConfig
} from '@jarvis/web-stratus-client'
import { JarvisNotificationsTable } from './JarvisNotificationsTable'
import { JarvisNotificationsList } from './JarvisNotificationsList'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
import './shared.scss'
import { checkProps, checkStack } from '../../utils/shared'
import {
  OnClickActionFunction,
  OnError,
  ShouldShowAction
} from './ActionComponentInterfaces'
import { ATLAS_PATH, getPath } from '../../utils/logInfo'
import { JarvisPortalPage } from '@jarvis/react-portal-additions'
import { useInternetConnectionChecker } from '../../../src/hooks/useInternetConnectionChecker'
import ErrorWidget from '../../../src/components/ErrorWidget'

/**
 * JarvisAtlasInbox Properties
 */
export type JarvisAtlasInboxProps = {
  /** The user country in ISO 3166-1 alpha-2 format */
  country: string

  /** The user language in ISO 639-1 format */
  language: string

  /** A JarvisAuthProvider interface implementation to authenticate atlas inbox API requests */
  authProvider: JarvisAuthProvider

  /** Base url of the atlas stack you want to get messages from */
  baseURLProvider: BaseURLProvider

  /** The current environment stack */
  stack: Stack

  /** SubType of notifications to retrieve */
  subtype: Array<string>

  /** Display Mode of notifications to retrieve */
  displayMode: string

  /** Amount of notifications to retrieve. If not supplied, Atlas API will return 10 by default, if available. */
  count: number

  /** If passed in, the component will display this mocked data. */
  mockJsonData?: Array<AtlasInboxNotification>

  /**
   * Delegate Function to decide if the action button should be rendered
   */
  shouldShowAction: ShouldShowAction
  /**
   * Action on click function to be fired
   */
  onClickActionFunction: OnClickActionFunction
  /**
   * Delegate function to handle error
   */
  onError: OnError
  /**
   * If passed true, the component will hide the contextual menu in notifications menu
   */
  hideContextualMenu?: boolean

  /**
   * Placeholder property that will be replaced by HOC function 'withToast' (see WrappedNotificationSettingsCategories)
   */
  addToast?: (toastOptions: ToastNotificationType) => void

  /**
   * Placeholder property that will be replaced by HOC function 'withToast' (see WrappedNotificationSettingsCategories)
   */
  removeToast?: (toastId: string) => void
}

type AtlasInboxNotification = any

/**
 * React component that renders Atlas Inbox Notifications.
 * @component
 * @param {JarvisAtlasInboxProps} props The react props
 */
export const JarvisAtlasInbox = (props: JarvisAtlasInboxProps) => {
  const [readNotifications, setReadNotifications] = React.useState(null)
  const [unreadNotifications, setUnreadNotifications] = React.useState(null)
  const [loadFailure, setLoadFailure] = React.useState(false)
  const [getNotificationsRetryTimeout, setGetNotificationsRetryTimeout] =
    React.useState(null)
  const getNotificationsRetryDelay = 60000
  const isNotificationUnread = (notification) =>
    notification?.flags.read === false

  const isNotificationValid = (notification) =>
    notification?.serial &&
    notification.uuid &&
    notification.message?.title &&
    notification.message.body
  checkProps(props.baseURLProvider, props.stack, props.mockJsonData)
  const inboxCheck = checkStack(props.baseURLProvider, props.stack)
  const inboxClient = new InboxClient(inboxCheck, props.authProvider)
  const assetsProvider = AssetsProviderFactory.create(
    props.language,
    props.country
  )
  const direction = assetsProvider.getDirection()

  useEffect(() => {
    loadInboxNotifications()
    return () => {
      // This function is ran when the component is removed from the DOM
      clearTimeout(getNotificationsRetryTimeout)
    }
  }, [])

  useEffect(() => {
    if (loadFailure) {
      const toastId =
        'jarvis-atlas-inbox-failed-to-retrieve-notifications-toast'
      const toastOptions: ToastNotificationType = {
        id: toastId,
        text: assetsProvider.getText('notifications.failed.load.toast.title'),
        subtitle: assetsProvider.getText(
          'notifications.failed.load.toast.subtitle'
        ),
        timeout: 5,
        type: 'negative',
        onClose: () => {
          props.removeToast(toastId)
        }
      }
      props.addToast(toastOptions)
    }
  })

  const loadInboxNotifications = async () => {
    let mounted = true
    // Make stratus atlas api call to get notifications
    let notifications: Array<AtlasInboxNotification> | null = null
    if (props.mockJsonData) {
      notifications = props.mockJsonData
    } else {
      const getMessagesConfig: GetMessagesConfig = {}
      if (props.subtype) {
        getMessagesConfig.subtype = props.subtype
      }
      if (props.displayMode) {
        getMessagesConfig.displayMode = props.displayMode
      }
      const stack =
        Stack[props.stack] === 'prod' ? '' : Stack[props.stack] + '-'
      const getMessageCountConfig: GetMessageCountConfig = {
        read: false,
        ...getMessagesConfig
      }
      getMessagesConfig.count = (
        await new InboxClient(
          `https://${stack}us1.api.ws-hp.com/atlas/v2/`,
          props.authProvider
        ).getMessageCount(getMessageCountConfig)
      ).data.count
      let failureDuringRetrieval = false
      const startTime = new Date().getTime()
      try {
        const newUnreadNotifications = getMessagesConfig.count
          ? (
              await inboxClient.getMessages({
                read: false,
                ...getMessagesConfig
              })
            ).data
          : []
        getMessagesConfig.count = 10
        const oldNotifications = (
          await inboxClient.getMessages({ read: true, ...getMessagesConfig })
        ).data
        notifications = newUnreadNotifications.concat(oldNotifications)
      } catch (error) {
        failureDuringRetrieval = true
        setGetNotificationsRetryTimeout(
          setTimeout(() => loadInboxNotifications(), getNotificationsRetryDelay)
        )
        const host = await props.baseURLProvider(
          inboxClient.apiName,
          inboxClient.apiVersion
        )
        await props.onError({
          host,
          path: getPath(ATLAS_PATH.GET_MESSAGE, getMessagesConfig),
          method: 'GET',
          code: error.status,
          payload: error,
          latency: new Date().getTime() - startTime
        })
      }
      setLoadFailure(failureDuringRetrieval)
    }

    if (mounted && !loadFailure && notifications) {
      const unreadNotifications = []
      const readNotifications = []
      notifications.filter(isNotificationValid).map((notification) => {
        const date = new Date(0)
        date.setUTCSeconds(notification.serial)
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        } as const

        const inboxNotification = {
          title: notification.message.title,
          message: notification.message.body,
          actions: notification.message.actions,
          level: notification.configs.level,
          id: notification.uuid,
          read: notification.flags.read,
          time: notification.serial, // time since EPOCH
          date: date.toLocaleDateString(
            props.language + '-' + props.country,
            options
          )
        }

        if (isNotificationUnread(notification)) {
          // Make stratus atlas api call to set notification as read
          setMessageAsRead(notification.uuid)
          unreadNotifications.push(inboxNotification)
        } else {
          if (readNotifications.length < 10)
            readNotifications.push(inboxNotification)
        }
        return inboxNotification
      })

      setReadNotifications(readNotifications || [])
      setUnreadNotifications(unreadNotifications || [])
    }
    return () => (mounted = false)
  }

  const setMessageAsRead = async (uuid) => {
    const startTime = new Date().valueOf()
    try {
      await inboxClient.setRead(uuid)
    } catch (error) {
      const host = await props.baseURLProvider(
        inboxClient.apiName,
        inboxClient.apiVersion
      )
      await props.onError({
        host,
        path: getPath(ATLAS_PATH.SET_READ, uuid),
        method: 'POST',
        code: error.code,
        payload: error,
        latency: new Date().getTime() - startTime
      })
    }
  }

  const renderNoNotifications = () => {
    const content = (
      <>
        {/* <img
          src={NoNotificationsImage}
          className="jarvis-notifications-card-no-notifications-image"
        /> */}
        <div>{assetsProvider.getText('notifications.noNotifications')}</div>
      </>
    )
    return renderJarvisNotificationsCard(content)
  }

  const renderJarvisNotificationsCard = (content) => {
    return (
      <Card
        className="jarvis-notifications-card"
        data-testid="jarvis-notifications-card"
        content={
          <div
            data-testid="jarvis-notifications-card-inner"
            className="jarvis-notifications-card-inner"
            dir={direction}
          >
            {content}
          </div>
        }
      />
    )
  }

  if (unreadNotifications !== null && readNotifications !== null) {
    if (unreadNotifications.length === 0 && readNotifications.length === 0) {
      return renderNoNotifications()
    }
    const olderTableTitle =
      unreadNotifications.length === 0
        ? assetsProvider.getText('notifications.notifications')
        : assetsProvider.getText('notifications.older')
    return (
      <div className="notifications-page" dir={direction}>
        <div className="notifications-page-as-table">
          <JarvisNotificationsTable
            notifications={unreadNotifications}
            title={assetsProvider.getText('notifications.new')}
            assetsProvider={assetsProvider}
            shouldShowAction={props.shouldShowAction}
            onClickActionFunction={props.onClickActionFunction}
            direction={direction}
          />
          <JarvisNotificationsTable
            notifications={readNotifications}
            title={olderTableTitle}
            assetsProvider={assetsProvider}
            shouldShowAction={props.shouldShowAction}
            onClickActionFunction={props.onClickActionFunction}
            direction={direction}
          />
        </div>
        <Card
          className="notifications-page-as-list"
          content={
            <>
              <JarvisNotificationsList
                notifications={unreadNotifications}
                title={assetsProvider.getText('notifications.new')}
                shouldShowAction={props.shouldShowAction}
                onClickActionFunction={props.onClickActionFunction}
              />
              <JarvisNotificationsList
                notifications={readNotifications}
                title={olderTableTitle}
                shouldShowAction={props.shouldShowAction}
                onClickActionFunction={props.onClickActionFunction}
              />
            </>
          }
        />
      </div>
    )
  } else {
    return renderJarvisNotificationsCard(<ProgressIndicator />)
  }
}

export const JarvisAtlasInboxPage = withToast(
  (props: JarvisAtlasInboxProps) => {
    const assetsProvider = AssetsProviderFactory.create(
      props.language,
      props.country
    )

    const { hasConnectionInternet } = useInternetConnectionChecker()
    const getText = (stringId: string) => assetsProvider.getText(stringId)

    if (!hasConnectionInternet) {
      return (
        <ErrorWidget
          getText={getText}
          forceFetch={() => window.location.reload()}
        />
      )
    }

    return (
      <JarvisPortalPage
        data-testid="jarvis-portal-page"
        title={assetsProvider.getText('notifications.notifications')}
        description={assetsProvider.getText('notifications.main.subtitle')}
        direction={assetsProvider.getDirection()}
        useGrid={false}
        mainContent={
          <JarvisAtlasInbox
            {...props}
            data-testid="jarvis-atlas-inbox"
            displayMode={'inbox'}
          />
        }
      />
    )
  }
)
