import {
  Action,
  CDMEventData,
  DataCollectionEventNames,
  EventServicePlugin,
  EventServicePluginError,
  Publisher,
  SimpleUiEvent,
  Subscriber
} from '@jarvis/jweb-core'
import { JWeb as JWebType } from '@jarvis/jweb-core/dist/definitions'
import getNativePlugins from '../utils/getNativePlugins'
import { DataCollectionService } from '@jarvis/web-data-collection'

const eventName = DataCollectionEventNames?.cdmEvent
const publisherId = 'com.hp.atlasbellnotifications.datacollection.publisher'
let _JWeb: JWebType = null

// Creates a Publisher and publish event
let eventService: EventServicePlugin
let simpleUiPublisher: Publisher

export const screenName = 'NotificationInbox'
export const activity = 'AtlasBellNotification-v01'
export const screenPath = '/ReactAtlasNotifications/'
export const version = '1.4.0'

export interface CustomDataSimpleUiEvent {
  action: Action
  screenName: string
  screenMode?: string
  controlName?: string
}

const commonData = {
  activity,
  version,
  screenPath
}

export const createSimpleUiEvent = (
  customData: CustomDataSimpleUiEvent
): SimpleUiEvent => {
  return {
    ...commonData,
    ...customData
  }
}

declare global {
  interface Window {
    JWeb: JWebType
  }
}

export const sendSimpleUiEvent = async (simpleUiEvent: SimpleUiEvent) => {
  console.debug('sendSimpleUiEvent method called')
  try {
    if (!simpleUiEvent) {
      throw new Error('SimpleUi event payload invalid')
    }

    if (!eventService) {
      const availablePlugins = await getPlugins()
      setEventService(availablePlugins.EventService)
    }

    if (!simpleUiPublisher) {
      const publisher = await getPublisher(eventService, publisherId)
      setPublisher(publisher)
    }

    await sendSimpleUiEventWithEventService(simpleUiEvent)
  } catch (error) {
    console.debug('Error trying to create simpleUi publisher.', error)
  }
}

async function getPlugins() {
  try {
    if (await isNative()) {
      const availablePlugins = await getNativePlugins()
      return availablePlugins
    } else {
      const JwebPromise = await DataCollectionService.getJWeb()
      const availablePlugins = JwebPromise.JWeb.Plugins
      return availablePlugins
    }
  } catch (error) {
    throw new Error('JWeb plugins not available.')
  }
}

async function getJWeb() {
  if (!_JWeb) {
    const JWebInstance = window.JWeb
    if (JWebInstance) {
      _JWeb = JWebInstance
    } else {
      throw new Error('JWeb plugins not available')
    }
  }
  return _JWeb
}

async function isNative() {
  return (await getJWeb())?.isNative
}

const setPublisher = (publisher: Publisher | EventServicePluginError) => {
  if ((publisher as EventServicePluginError).errorType !== undefined)
    throw new Error('Could not create publisher')
  simpleUiPublisher = publisher as Publisher
}

const setEventService = (
  eventServicePlugin: EventServicePlugin | EventServicePluginError
) => {
  if ((eventServicePlugin as EventServicePluginError).errorType !== undefined)
    throw new Error('Could not create eventService')
  eventService = eventServicePlugin as EventServicePlugin
}

async function sendSimpleUiEventWithEventService(simpleUiEvent: SimpleUiEvent) {
  console.debug('Sending simpleUi event: ', JSON.stringify(simpleUiEvent))

  const eventData = createEventData(simpleUiEvent)

  // Publishes event
  if (simpleUiPublisher) {
    await publishEvent(simpleUiPublisher, eventName, eventData)
  }
}

// Creating Event Data

const createEventData = (simpleUiEvent: SimpleUiEvent): any => {
  const date = new Date()
  const formattedDate = date.toISOString()
  const cdmEventData: CDMEventData = {
    dateTime: formattedDate,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail: simpleUiEvent
  }

  return {
    events: [cdmEventData]
  }
}

export const getPublisher = async (
  eventService: EventServicePlugin,
  publisherId: string
) => {
  console.log('getPublisher method called')
  const publisher = await eventService.createPublisher(publisherId)

  if (hasEventServicePluginError(publisher as EventServicePluginError)) {
    console.log(
      'getPublisher method failed with: ',
      (publisher as EventServicePluginError).errorType,
      (publisher as EventServicePluginError).reason
    )
    throw new Error('Could not create publisher')
  } else return publisher as Publisher
}

export const hasEventServicePluginError = (
  subscriber: Publisher | Subscriber | EventServicePluginError
) => {
  console.log('has event service plugin error', subscriber)
  return (subscriber as EventServicePluginError).errorType !== undefined
}

const publishEvent = async (
  publisher: Publisher,
  eventName: string,
  eventData: any = {}
) => {
  console.log('publish method called')
  const response = await publisher.publish(eventName, eventData)
  if (
    response &&
    hasEventServicePluginError(response as EventServicePluginError)
  ) {
    console.log(
      `Publishing event ${eventName} failed`,
      (response as EventServicePluginError).errorType,
      (response as EventServicePluginError).reason
    )
    return false
  } else console.log(`Publishing ${eventName} Success`)
}
