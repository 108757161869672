import React from 'react'
import List from '@veneer/core/dist/scripts/list'
import { JarvisNotificationsListItem } from './JarvisNotificationsListItem'
import './index.scss'
import '../shared.scss'
import {
  ShouldShowAction,
  OnClickActionFunction
} from '../ActionComponentInterfaces'
type JarvisNotificationsListProps = {
  /** Array of Inbox Notifications */
  notifications: Array<any>
  title: string
  shouldShowAction: ShouldShowAction
  onClickActionFunction: OnClickActionFunction
}

export class JarvisNotificationsList extends React.Component<
  JarvisNotificationsListProps,
  Record<string, never>
> {
  constructor(props) {
    super(props)
  }

  resolveNotificationList() {
    if (this.props.notifications.length === 0) {
      return <></>
    }
    return (
      <div>
        <div
          data-testid="jarvis-notifications-list-section"
          className="jarvis-notifications-list__section"
        >
          {this.props.title}
        </div>
        {this.props.notifications.map((item) => {
          return (
            <JarvisNotificationsListItem
              title={item.title}
              message={item.message}
              level={item.level}
              actions={item.actions}
              key={item.id}
              id={item.id}
              date={item.date}
              shouldShowAction={this.props.shouldShowAction}
              onClickActionFunction={this.props.onClickActionFunction}
            />
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <List
        className="jarvis-notifications-list"
        data-testid="jarvis-notifications-list"
      >
        {this.resolveNotificationList()}
      </List>
    )
  }
}
