import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const NotificationCenterContainer = styled.div`
  && {
    div > div:nth-of-type(1) > div:nth-of-type(2) {
      min-height: unset !important;
      > div:nth-of-type(1) {
        min-height: unset !important;
      }
    }

    li > button {
      min-width: 36px;
      width: 36px;
      padding: 6px 12px;
      span {
        margin-right: 0;
      }
      svg {
        fill: #212121;
      }
      button {
        display: none;
      }
    }
    .notification_date_and_action > .notification_date {
      font-size: 14px !important;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .notification_right_area {
      position: fixed;
      inset: 0px auto auto 0px;
      transform: translate(140px, 54px);
    }
  }
`

export const NotificationActionLinkButton = styled.div`
  && {
    display: block;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-family: ${tokens.fontFamilyLight};
    word-break: break-word;

    :hover {
      cursor: pointer;
      color: #035c84;
    }

    @media {
      font-size: 16px;
    }
  }
`
