import styled from 'styled-components'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import tokens from '@veneer/tokens'

// colors defined by design
const errorColor = '#C70000'
const textMessageColor = '#464D50'

export const Icon = styled(IconMinusCircle)`
  && {
    fill: ${errorColor};
    overflow: unset;
    width: 33px;
    height: 33px;
  }
`

export const NotificationBannerIcon = styled.div``

export const NotificationBannerMessage = styled.div`
  text-align: ${(props) => (props.direction === 'ltr' ? 'left' : 'right')};
`

export const NotificationBannerAction = styled.div``

export const NotificationBannerItem = styled.div`
  background-color: #fff;
  border: 1px solid ${errorColor};
  border-radius: 12px;
  color: ${textMessageColor};
  opacity: 1;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 12px 40px #6a6a6a1a;
  margin-bottom: 20px;

  ${NotificationBannerIcon} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: auto;
    -ms-flex-item-align: center;
  }

  ${NotificationBannerMessage} {
    padding-left: 10px;
    margin: auto;
    flex: auto;

    h3 {
      font-family: ${tokens.fontFamilyLight};
      font-size: 20px;
      line-height: 22px;
    }

    p {
      font-family: ${tokens.fontFamilyLight};
      font-size: 14px;
      line-height: 22px;
    }
  }

  ${NotificationBannerAction} {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: auto;
    -ms-flex-item-align: center;

    button {
      padding: 5px;
    }
  }

  @media (max-width: ${(props) =>
      (props.theme && props.theme.breakPoints && props.theme.breakPoints.md) ||
      '600px'}) {
    padding: 10px;
    flex-direction: column;

    ${NotificationBannerIcon} {
      flex-direction: row;
    }

    ${NotificationBannerMessage} {
      padding: 0;

      h3 {
        text-align: center;
        margin-bottom: 8px;
      }

      p {
        line-height: 18px;
        text-align: center;
        margin-bottom: 8px;
      }
    }

    ${NotificationBannerAction} {
      flex-direction: row;

      button {
        margin-top: 10px;
        font-size: 16px;
        line-height: 24px;
        padding: 5px 50px;
        border-radius: 12px;
      }
    }
  }
`
