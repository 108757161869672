import styled from 'styled-components'
import Tokens from '@veneer/tokens'

export const ErrorPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Messages = styled.div`
  display: flex;
  max-width: 37.5rem;
  justify-content: center;
  text-align: center;
  margin: 1rem 1rem 0;
  color: ${Tokens.colorGray10};
`

export const TryAgainButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${Tokens.space5};
`
