import React, { useCallback } from 'react';
import AtlasInboxNotificationsPage from '../components/AtlasInboxNotificationsPage';
import BannerNotifications from '../components/BannerNotifications';
import getBaseUrl from '../hooks/getBaseUrl';
import Bell from '../components/Bell';
import { IdleTimer } from '../contexts/IdleTimer';
import { UseRootContextType } from '../types/mfeProps';
import { useRootContext } from '@jarvis/react-portal-addons';
import { Stack } from '../types/shell';
import { MFE_TYPES } from 'src/constants/mfeTypes';

const App = () => {
  const { mfeType, stack }: UseRootContextType = useRootContext();

  const getNotificationComponent = useCallback(() => {
    const baseUrlProvider = (apiName, apiVersion) =>
      getBaseUrl(Stack[stack], apiName, apiVersion);

    switch (mfeType) {
      case MFE_TYPES.BANNER:
        return <BannerNotifications baseUrlProvider={baseUrlProvider} />;
      case MFE_TYPES.BELL:
        return <Bell baseUrlProvider={baseUrlProvider} />;
      default:
        return (
          <AtlasInboxNotificationsPage baseUrlProvider={baseUrlProvider} />
        );
    }
  }, [mfeType, stack]);

  return <IdleTimer>{getNotificationComponent()}</IdleTimer>;
};

export default App;
