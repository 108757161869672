import React from 'react'
import {
  resolveActionComponent,
  ActionComponentTypes
} from '../../NotificationActionHelper'
import {
  NotificationBannerAction,
  NotificationBannerItem,
  NotificationBannerMessage,
  NotificationRedirectLink
} from './styles'
import { NotificationActionLinkButton } from '../../JarvisInboxNotification/styles'

const renderNotificationBody = (notification, direction) => {
  return (
    <NotificationBannerMessage direction={direction}>
      <p>{notification.message.body}</p>
    </NotificationBannerMessage>
  )
}

const onClickWrapper = (e, onClickAction) => {
  e.nativeEvent &&
    e.nativeEvent.stopImmediatePropagation &&
    e.nativeEvent.stopImmediatePropagation()
  e.stopPropagation()
  e.preventDefault()
  onClickAction()
}

export const RedirectLinkComponent = ({ label, onClickAction }) => (
  <NotificationActionLinkButton
    data-testid="redirect-link"
    key={label}
    onClick={(e) => onClickWrapper(e, onClickAction)}
  >
    <a href="#">{label}</a>
  </NotificationActionLinkButton>
)

const JarvisBannerNotificationContent = ({
  notification,
  linkTo,
  shouldShowAction,
  onClickActionFunction,
  direction
}) => (
  <NotificationBannerItem
    id={`portal-notifications-banner-${notification.uuid}`}
    key={notification.uuid}
  >
    {renderNotificationBody(notification, direction)}
    <NotificationRedirectLink>
      {linkTo && (
        <RedirectLinkComponent
          label={linkTo.label}
          onClickAction={linkTo.onClick}
        />
      )}
    </NotificationRedirectLink>
    <NotificationBannerAction>
      {resolveActionComponent(
        notification.message.actions,
        ActionComponentTypes.BANNER_NOTIFICATION,
        shouldShowAction,
        onClickActionFunction
      )}
    </NotificationBannerAction>
  </NotificationBannerItem>
)

export default JarvisBannerNotificationContent
