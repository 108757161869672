export const Environments = {
  dev: {
    AUTHZ_ONBOARDING_BASE_URL: ' https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL:
      'https://devices.pie-us1.api.ws-hp.com/devices',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com'
  },
  pie: {
    AUTHZ_ONBOARDING_BASE_URL: 'https://pie-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL:
      'https://devices.pie-us1.api.ws-hp.com/devices',
    STRATUS_BASE_URL: 'https://pie-us1.api.ws-hp.com'
  },
  stage: {
    AUTHZ_ONBOARDING_BASE_URL: 'https://stage-us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL:
      'https://devices.stage-us1.api.ws-hp.com/devices',
    STRATUS_BASE_URL: 'https://stage-us1.api.ws-hp.com'
  },
  prod: {
    AUTHZ_ONBOARDING_BASE_URL: 'https://us1.api.ws-hp.com/ucde/ucde',
    AUTHZ_ONBOARDING_DEVICES_BASE_URL:
      'https://devices.us1.api.ws-hp.com/devices',
    STRATUS_BASE_URL: 'https://us1.api.ws-hp.com'
  }
};
