import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  background-color: transparent;
  color: ${tokens.colorGray7};
  padding: 40px;
  /* the same breakpoint as profile and users pages */
  @media (max-width: 375px) {
    padding: ${tokens.space6} ${tokens.space4};
  }
`;
