import styled from 'styled-components'
import tokens from '@veneer/tokens'

// colors defined by design
const textMessageColor = '#464D50'

export const NotificationBannerMessage = styled.div`
  text-align: ${(props) => (props.direction === 'ltr' ? 'left' : 'right')};
`

export const NotificationBannerAction = styled.div``

export const NotificationRedirectLink = styled.div``

export const NotificationBannerItem = styled.div`
  color: ${textMessageColor};
  display: grid;
  grid-gap: 15px;
  justify-content: space-between;
  grid-template-columns: 3fr minMax(0, auto);
  grid-template-rows: auto auto;
  grid-template-areas:
    'content content'
    'link button';
  padding: 10px;

  ${NotificationBannerMessage} {
    grid-area: content;
    p {
      max-width: 660px;
      font-family: ${tokens.fontFamilyLight};
      font-size: 16px;
      line-height: 20px;
      color: #737373;
    }
  }

  ${NotificationRedirectLink} {
    grid-area: link;
    min-width: 100px;
  }

  ${NotificationBannerAction} {
    grid-area: button;
    padding-left: 12px;
    flex-direction: column;
    flex-shrink: 0;
    -ms-flex-item-align: center;

    button {
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: ${(props) =>
      (props.theme && props.theme.breakPoints && props.theme.breakPoints.md) ||
      '600px'}) {
    flex-direction: column;
    grid-template-columns: minMax(0, auto);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'content'
      'link'
      'button';

    ${NotificationBannerMessage} {
      max-width: 100%;
      p {
        padding-right: 0px;
      }
    }

    ${NotificationBannerAction} {
      padding-left: 0px;
    }
  }
`
