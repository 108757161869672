import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const HeaderArea = ({
  leftArea,
  rightArea,
  centerArea,
  columnDirectionToMobileScreen,
}) => (
  <Container columnDirectionToMobileScreen={columnDirectionToMobileScreen}>
    {leftArea}
    {centerArea}
    {rightArea}
  </Container>
);

HeaderArea.defaultProps = {
  rightArea: null,
  leftArea: null,
  centerArea: null,
  columnDirectionToMobileScreen: false,
};

HeaderArea.propTypes = {
  rightArea: PropTypes.node,
  leftArea: PropTypes.node,
  centerArea: PropTypes.node,
  columnDirectionToMobileScreen: PropTypes.bool,
};

export default HeaderArea;
