import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '../Accordion'
import { InboxClient } from '@jarvis/web-stratus-client'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
import { NotificationError } from '../../utils/errors.tsx'
import { ATLAS_PATH, getPath } from '../../utils/logInfo'
import {
  resolveIcon,
  resolveType,
  resolveDate,
  resolveColor,
  resolveSeverity,
  checkProps,
  checkStack
} from '../../utils/shared'
import JarvisBannerNotificationContent from './JarvisBannerNotificationContent'
import { DateWrapper, NotificationMultipleBannerContainer } from './styles'

export const JarvisMultipleBannerNotification = ({
  country,
  language,
  authProvider,
  notificationCount,
  onError,
  mockJsonData,
  linkTo,
  baseURLProvider,
  stack,
  refreshDelay,
  shouldShowAction,
  onClickActionFunction,
  autoRefresh
}) => {
  const [bannerNotificationsItems, setBannerNotificationsItems] = useState([])
  checkProps(baseURLProvider, stack, mockJsonData)
  const inboxCheck = checkStack(baseURLProvider, stack)
  const inboxClient = new InboxClient(inboxCheck, authProvider)
  const assetsProvider = AssetsProviderFactory.create(language, country)
  const direction = assetsProvider.getDirection()
  const isNotificationValid = (data) =>
    data &&
    data.serial &&
    data.uuid &&
    data.message &&
    data.message.title &&
    data.message.body &&
    data.message.content_type == 'text/plain'

  async function loadBannerNotifications(notificationCount) {
    let getMessagesParams = { displayMode: 'banner' }
    let highestPriorityNotifications
    let startTime = new Date().getTime()
    try {
      const data =
        mockJsonData || (await inboxClient.getMessages(getMessagesParams)).data
      highestPriorityNotifications = filterByPriority(data, notificationCount)
    } catch (error) {
      const host = await baseURLProvider(
        inboxClient.apiName,
        inboxClient.apiVersion
      )
      await onError({
        host,
        path: getPath(ATLAS_PATH.GET_MESSAGE, getMessagesParams),
        method: 'GET',
        code: error.status,
        payload: error,
        latency: new Date().getTime() - startTime
      })
      highestPriorityNotifications = NotificationError(error, country, language)
    }
    let notificationItems = buildNotificationItems(highestPriorityNotifications)
    setBannerNotificationsItems(notificationItems)
  }

  /**
   * Very Low Priority -10
   * Low Priority -5
   * Normal Priority 0
   * Normal Priority (default) 0
   * High Priority 5
   * Very High Priority 10
   */
  const filterByPriority = (notifications, notificationCount) => {
    return notifications
      .sort((first, second) => {
        if (first?.configs?.priority === second?.configs?.priority) {
          // they have the same priority so older has higher priority
          return first.serial - second.serial
        } else if (first?.configs?.priority > second?.configs?.priority) {
          return -1
        }
        return 1
      })
      .slice(0, notificationCount)
  }

  let timeoutId = null
  let fetching = false

  function cancelScheduledFetch() {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  async function fetchData() {
    if (fetching) {
      return
    }
    fetching = true
    timeoutId = null
    await loadBannerNotifications(notificationCount)
    timeoutId = setTimeout(fetchData, refreshDelay)
    fetching = false
  }

  useEffect(() => {
    if (autoRefresh) {
      fetchData()
    } else {
      cancelScheduledFetch()
    }
    return () => {
      cancelScheduledFetch()
    }
  }, [autoRefresh])

  const toggleAccordion = (toggle, index, items) => {
    const updatedData = [...items]
    updatedData[index].expanded = toggleAccordion
    setBannerNotificationsItems(updatedData)
  }

  const handleExpand = (_, index) => {
    toggleAccordion(true, index, bannerNotificationsItems)
    return true
  }

  const handleCollapse = (_, index) => {
    toggleAccordion(false, index, bannerNotificationsItems)
    return false
  }

  const buildNotificationItems = (bannerNotifications) => {
    const filteredItems = bannerNotifications
      ?.filter(isNotificationValid)
      .sort((first, second) => {
        return (
          resolveSeverity(first?.configs?.level) -
          resolveSeverity(second?.configs?.level)
        )
      })
    return filteredItems.map((notification) => {
      return {
        header: {
          startArea: resolveIcon(notification, true),
          centralArea: (
            <div>
              <span>{notification.message.title}</span>
              <DateWrapper>
                {resolveDate(notification?.serial, language, country)}
              </DateWrapper>
            </div>
          )
        },
        borderColor: resolveColor(notification),
        content: (
          <JarvisBannerNotificationContent
            notification={notification}
            linkTo={linkTo}
            shouldShowAction={shouldShowAction}
            onClickActionFunction={onClickActionFunction}
            direction={direction}
          />
        ),
        id: `banner-item-${resolveType(notification?.configs?.level)}-${
          notification.uuid
        }`
      }
    })
  }

  return (
    <NotificationMultipleBannerContainer>
      <Accordion
        id="portal-multiple-notifications-banner"
        data-testid="portal-multiple-notifications-banner"
        behavior="singleExpand"
        items={bannerNotificationsItems}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
      />
    </NotificationMultipleBannerContainer>
  )
}

JarvisMultipleBannerNotification.defaultProps = {
  mockJson: null,
  onError: (host, path, method, code, payload, latency) => {
    console.log('OnError Method', {
      host,
      path,
      method,
      code,
      payload,
      latency
    })
  },
  refreshDelay: 300000, // 5 min
  autoRefresh: true
}

JarvisMultipleBannerNotification.propTypes = {
  /**
   * the user language in ISO 639-1 format
   */
  language: PropTypes.string.isRequired,
  /**
   * the user country in ISO 3166-1 alpha-2 format
   */
  country: PropTypes.string.isRequired,
  /**
   * a JarvisAuthProvider interface implementation to authenticate atlas inbox API requests
   */
  authProvider: PropTypes.object.isRequired,
  /**
   * maximum notifications to be shown
   */
  notificationCount: PropTypes.number.isRequired,
  /**
   * Delegate function to be called when an error occurs.
   * should expect an object as parameter like the following
   * {
   *   host,
   *   path,
   *   method,
   *   code,
   *   payload,
   *   latency,
   * }
   */
  onError: PropTypes.func,
  /**
   * Optional Mock Data to supply to component
   */
  mockJsonData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Json with the label and delegate function for redirecting user to view all notifications
   */
  linkTo: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  /**
   * Base Url to make api requests to
   */
  baseURLProvider: PropTypes.func,
  /**
   * The current environment stack
   */
  stack: PropTypes.number,
  /**
   * Delegate function that decides when the action component will be rendered
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  shouldShowAction: PropTypes.func.isRequired,
  /**
   * onClick Action function to be fired when action component gets clicked
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  onClickActionFunction: PropTypes.func.isRequired,
  /**
   * Milliseconds to refresh the notifications - if not defined it will use the default value 5 minutes
   */
  refreshDelay: PropTypes.number,
  autoRefresh: PropTypes.bool
}

export default JarvisMultipleBannerNotification
