import { useEffect, useState } from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(`screen and ${query}`);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const handler = () => setMatches(media.matches);

    media.addEventListener('change', handler);
    return () => media.removeEventListener('change', handler);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
