import AssetsProviderFactory from '../assets/AssetsProviderFactory'

export enum ErrorType {
  GENERIC_ERROR = 'error'
}

export const NotificationError = (error, country, language) => {
  const assetsProvider = AssetsProviderFactory.create(language, country)
  const currentDate = new Date()
  return [
    {
      uuid: 'error',
      message: {
        content_type: 'error',
        title: assetsProvider.getText('notifications.failed.load.toast.title'),
        body: assetsProvider.getText(
          'notifications.failed.load.toast.subtitle'
        ),
        extra: error
      },
      flags: {
        user_dismissible: false
      },
      configs: {
        level: 'fatal'
      },
      serial: (currentDate.getTime() - currentDate.getMilliseconds()) / 1000
    }
  ]
}
