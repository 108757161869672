import React, { useContext } from 'react';
import { useNotificationsPageDeepLink } from '../../hooks/useNotificationsPageDeepLink';
import { JarvisMultipleBannerNotifications } from '@jarvis/react-atlas-notifications';
import { useI18n } from '@jarvis/react-portal-addons';
import { onClickActionFunction, shouldShowAction } from '../../utils/deeplinks';
import { Container } from './styles';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../../types/mfeProps';
import { IdleTimerContext } from '../../contexts/IdleTimer';

const BannerNotifications = ({ baseUrlProvider }) => {
  const notificationsPageDeeplink = useNotificationsPageDeepLink();
  const { pollingEnabled } = useContext(IdleTimerContext);
  const { t } = useI18n();
  const { authProvider, navigation, country, language }: UseRootContextType =
    useRootContext();

  return (
    <Container data-testid="banner-notifications-container">
      <JarvisMultipleBannerNotifications
        country={country}
        language={language}
        authProvider={authProvider}
        notificationCount={3}
        linkTo={{
          label: t('home.bannerNotification.viewAllNotifications'),
          onClick: () => navigation.push(notificationsPageDeeplink || '')
        }}
        baseURLProvider={baseUrlProvider}
        shouldShowAction={shouldShowAction}
        onClickActionFunction={onClickActionFunction(
          country,
          language,
          navigation,
          'banner-notification'
        )}
        onError={async (error) => error} // TODO: make api call to /monitoring/logs (today this is done using the gateway).
        autoRefresh={pollingEnabled}
      />
    </Container>
  );
};

export default BannerNotifications;
