import { useMemo } from 'react';
import { getPortalDeeplinks, generateDeeplinkName } from '../utils/deeplinks';
import { pathOr } from 'ramda';
import packageJson from '../../package.json';

export const useNotificationsPageDeepLink = () => {
  const notificationsPageDeeplink = useMemo(() => {
    const deeplinks = getPortalDeeplinks();

    const shellRoutes = pathOr(
      [],
      ['manifest', 'navigation', 'routes'],
      window.Shell
    );

    const notificationsPageManifestObject = shellRoutes.reduce(
      (resultingObject, currentRoute) => {
        if (currentRoute?.assetReference === packageJson.name) {
          return { ...currentRoute };
        } else {
          const subRoutes = currentRoute?.subRoutes || [];

          if (subRoutes?.length) {
            const notificationsRoute = subRoutes.find(
              (route) => route.assetReference === packageJson.name
            );

            if (notificationsRoute) {
              return { ...notificationsRoute };
            }
          }
        }

        return { ...resultingObject };
      },
      {}
    );

    const notificationsDeeplinkName = generateDeeplinkName(
      notificationsPageManifestObject?.path || ''
    );

    return deeplinks[notificationsDeeplinkName];
  }, []);

  return notificationsPageDeeplink;
};
