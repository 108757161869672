import React from 'react'

import IconCheckCircle from '@veneer/core/dist/scripts/icons/icon_check_circle'
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import IconWarning from '@veneer/core/dist/scripts/icons/icon_warning'
import './shared.scss'

export const resolveColor = (notificationOrLevel, rating = 1) => {
  // Outside icon color, colorGreen5
  let color = null
  const level =
    typeof notificationOrLevel === 'string'
      ? notificationOrLevel
      : notificationOrLevel?.configs?.level

  switch (level) {
    case 'info':
    case 'success':
      color = `rgba(28, 122, 23, ${rating}`
      break
    case 'debug':
    case 'critical':
    case 'warning':
      color = `rgba(255, 222, 106, ${rating}`
      break
    case 'error':
    case 'fatal':
    case 'severe':
      color = `rgba(190, 19, 19, ${rating}`
      break
    default:
      break
  }
  return color
}

export const resolveIcon = (
  notificationOrLevel: string | any,
  withBackgroundColor = false
) => {
  // Outside icon color, colorGreen5
  let icon = null
  const level =
    typeof notificationOrLevel === 'string'
      ? notificationOrLevel
      : notificationOrLevel?.configs?.level

  switch (level) {
    case 'info':
      icon = <IconInfo color="colorGreen6" filled />
      break
    case 'success':
      icon = <IconCheckCircle color="colorGreen6" filled />
      break
    case 'debug':
      icon = <IconWarning color="colorDarkOrange5" filled />
      break
    case 'critical':
    case 'warning':
      icon = <IconWarningAlt color="colorDarkOrange5" filled />
      break
    case 'error':
    case 'fatal':
    case 'severe':
      icon = <IconMinusCircle color="colorRed6" filled />
      break
    default:
      break
  }
  if (!icon) {
    return <></>
  }

  if (withBackgroundColor) {
    return (
      <span
        className="jarvis-notification-icon"
        style={{ backgroundColor: resolveColor(notificationOrLevel, 0.1) }}
      >
        {icon}
      </span>
    )
  }

  return icon
}

export const resolveSeverity = (level) => {
  switch (level) {
    case 'error':
    case 'fatal':
    case 'severe':
      return 0
    case 'debug':
    case 'warning':
      return 1
    case 'info':
    case 'success':
      return 2
    default:
      return 3
  }
}

export const resolveType = (level) => {
  // type influences the color of the icon
  // positive -> green
  // negative -> red
  // warning -> yellow/orange

  switch (level) {
    case 'info':
    case 'success':
      return 'positive'
    case 'error':
    case 'fatal':
    case 'severe':
      return 'negative'
    case 'debug':
    case 'warning':
      return 'warning'
    default:
      return null
  }
}

export const resolveDate = (serial, language, country) => {
  let time = new Date()
  if (serial) {
    time = new Date(0)
    time.setSeconds(serial)
  }
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const
  try {
    return time.toLocaleDateString(`${language}-${country}`, options)
  } catch (e) {
    return e.name === 'RangeError'
  }
}

export const checkStack = (baseURLProvider, stack) => {
  return stack === undefined ? baseURLProvider : stack
}

export const checkProps = (baseURLProvider, stack, mockJsonData) => {
  if (baseURLProvider === null && stack === null && mockJsonData === null) {
    throw new Error('stack or Baseurl is required')
  }
}
