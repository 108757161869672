import React from 'react'
import ListItem from '@veneer/core/dist/scripts/list_item'
import { resolveIcon } from '../../../utils/shared'
import resolveActionComponent, {
  ActionComponentTypes
} from '../../NotificationActionHelper'

export const JarvisNotificationsListItem = ({
  title,
  message,
  level,
  actions,
  id,
  date,
  shouldShowAction,
  onClickActionFunction
}) => {
  const resolveActions = () =>
    resolveActionComponent(
      actions,
      ActionComponentTypes.INBOX_NOTIFICATION,
      shouldShowAction,
      onClickActionFunction
    )
  const resolveMessage = () => {
    return (
      <div>
        <div data-testid="list-item-title" className="vn-list-item__title">
          {title}
        </div>
        <div
          data-testid="list-item-item-subtitle"
          className="vn-list-item__subtitle"
        >
          {message}
        </div>
        <div
          data-testid="jarvis-notifications-list-date"
          className="jarvis-notifications-list__date"
        >
          {date}
        </div>
        <div>{resolveActions()}</div>
      </div>
    )
  }

  return (
    <ListItem
      data-testid="jarvis-notifications-list-item"
      className="jarvis-notifications-list__item"
      leadingArea={resolveIcon(level)}
      centerArea={resolveMessage()}
      rightArea={<></>}
      id={id}
    />
  )
}
