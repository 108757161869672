import React from 'react'
import { ErrorPageWrapper, Messages, TryAgainButton } from './styles'
import Button from '@veneer/core/dist/scripts/button/button'

interface ErrorWidgetProps {
  getText: (text: string) => string
  forceFetch: () => void
}

const ErrorWidget = ({ getText, forceFetch }: ErrorWidgetProps) => {
  return (
    <ErrorPageWrapper data-testid="error-widget">
      <Messages>{getText('errors.failToLoad')}</Messages>
      <TryAgainButton>
        <Button onClick={forceFetch} appearance="secondary">
          {getText('errorPage.button')}
        </Button>
      </TryAgainButton>
    </ErrorPageWrapper>
  )
}
export default ErrorWidget
