import React, { useState } from 'react'
import Table from '@veneer/core/dist/scripts/table'
import { resolveIcon } from '../../../utils/shared'
import './index.scss'
import resolveActionComponent, {
  ActionComponentTypes
} from '../../NotificationActionHelper'

export const JarvisNotificationsTable = ({
  title,
  notifications,
  assetsProvider,
  shouldShowAction,
  onClickActionFunction,
  direction
}) => {
  const items = notifications.map((notification) => {
    const actionElements = resolveActionComponent(
      notification.actions,
      ActionComponentTypes.INBOX_PAGE_NOTIFICATION,
      shouldShowAction,
      onClickActionFunction
    )

    const titleElement = <div>{notification.title}</div>

    const messageElement = (
      <div>
        {notification.message}
        <div className="message-column__action">{actionElements}</div>
      </div>
    )

    return {
      ...notification,
      message: messageElement,
      title: titleElement,
      icon: resolveIcon(notification.level, true)
    }
  })
  const orderBy = 'time'
  const columns = [
    {
      id: 'icon',
      label: 'icon',
      sortable: false
    },
    {
      id: 'title',
      label: title,
      sortable: false
    },
    {
      id: 'date',
      label: assetsProvider.getText('notifications.date'),
      sortable: true
    },
    {
      id: 'message',
      label: assetsProvider.getText('notifications.message'),
      sortable: false
    }
  ]

  const descendingComparator = (a, b, orderBy) => {
    const aSort = a[orderBy] && a[orderBy]
    const bSort = b[orderBy] && b[orderBy]
    return bSort < aSort ? -1 : bSort > aSort ? 1 : 0
  }

  const sortItems = (items: Array<Record<string, unknown>>, type: string) => {
    // Sort date so that it displays most recent on top, based on the time ('serial') value that the api returns for a notification
    return items.sort((a, b) => {
      const value =
        type === 'descending'
          ? descendingComparator(a, b, orderBy)
          : -descendingComparator(a, b, orderBy)
      return value
    })
  }
  const [sortState, setSortState] = useState({
    orderType: 'descending',
    sortedItems: sortItems(items, 'descending')
  })

  const handleSort = (_, { id, type }) => {
    const sortedItems = sortItems(items, type)
    setSortState({ ...sortState, sortedItems: sortedItems })
  }

  if (notifications && notifications.length === 0) {
    return <div></div>
  }

  return (
    <Table
      data-testid="jarvis-notifications-table"
      className={'jarvis-notifications-table ' + direction}
      columns={columns}
      data={sortState.sortedItems}
      onSort={handleSort}
      preferences={{
        sortBy: {
          id: 'date',
          type: 'descending'
        }
      }}
    />
  )
}
