import { pathOr } from 'ramda';
import { makePathRelative } from './routing';
import {
  HP_SMART_APP_SCHEMA,
  HPDL_SCHEMA
} from '../constants/notificationSchema';

export const generateDeeplinkName = (path) => {
  if (!path) return '';

  const regexPathSeparators = /(-)|(\/)/g;

  let deeplinkName = path?.replace(regexPathSeparators, '_').replace(':', '');

  if (deeplinkName.startsWith('_')) {
    deeplinkName = deeplinkName.substring(1);
  }

  if (deeplinkName.endsWith('_')) {
    deeplinkName = deeplinkName.substring(0, deeplinkName.length - 1);
  }

  return deeplinkName;
};

const generateDeeplinksFromPathList = (pathList, deeplinkPrefix = '') => {
  if (!Array.isArray(pathList)) return {};

  return pathList.reduce((resultingObject, currentPath) => {
    const deeplinkName = generateDeeplinkName(currentPath);

    if (deeplinkName) {
      return {
        ...resultingObject,
        [deeplinkPrefix ? `${deeplinkPrefix}_${deeplinkName}` : deeplinkName]:
          currentPath
      };
    }

    return resultingObject;
  }, {});
};

export const getPortalDeeplinks = () => {
  const portalRoutes = pathOr(
    [],
    ['manifest', 'navigation', 'routes'],
    window.Shell
  );

  if (portalRoutes.length) {
    const deeplinks = portalRoutes.reduce((resultingObject, currentRoute) => {
      let routes = {};
      const currentPath = currentRoute.path;

      // if the path is an array, we need to generate deeplinks for each element
      if (Array.isArray(currentPath)) {
        const deeplinksFromPathList =
          generateDeeplinksFromPathList(currentPath);
        return { ...resultingObject, ...deeplinksFromPathList };
      }

      // if the path is a string, we generate a single deeplink
      const currentPathDeeplinkName = generateDeeplinkName(currentPath);

      if (currentPathDeeplinkName) {
        routes[currentPathDeeplinkName] = currentPath;
      }

      // if the route has subroutes, we generate deeplinks for each subroute
      if (currentRoute.subRoutes?.length) {
        currentRoute.subRoutes.forEach((subRoute) => {
          // if the subroute path is an array, we generate nested deeplinks for each element
          const subRoutesDeeplinks = generateDeeplinksFromPathList(
            Array.isArray(subRoute.path) ? subRoute.path : [subRoute.path],
            currentPathDeeplinkName
          );

          routes = { ...routes, ...subRoutesDeeplinks };
        });
      }

      return { ...resultingObject, ...routes };
    }, {});

    return {
      ...deeplinks,
      consumer: '',
      ucde: ''
    };
  }

  return {};
};

export const isValidSchema = (schema) =>
  schema === HP_SMART_APP_SCHEMA || schema === HPDL_SCHEMA;

type BuildLinkPropsType = {
  schema: string;
  key: string;
  path: string;
  parameters: string;
  fragment: string;
  link: string;
  eventLinkPlacement?: string;
};

export const buildLink = ({
  schema,
  key,
  path,
  parameters,
  fragment,
  link
}: BuildLinkPropsType): string => {
  let resultLink = link;
  if (isValidSchema(schema)) {
    const deeplinkMap = getPortalDeeplinks();
    const base = deeplinkMap[key];
    if (typeof base !== 'undefined') {
      resultLink = `${base}${path ? `/${path}` : ''}${
        parameters ? `?${parameters}` : ''
      }${fragment ? `#${fragment}` : ''}`;
    } else {
      resultLink = '';
    }
  }
  return resultLink;
};

export const onClickActionFunction = (
  country,
  language,
  shellNavigation,
  linkPlacement
) => {
  const buildRelativePath = makePathRelative({ country, language });

  return ({
    schema,
    key,
    path,
    parameters,
    fragment,
    link,
    eventLinkPlacement = linkPlacement
  }) => {
    const resultLink = buildLink({
      schema,
      key,
      path,
      parameters,
      fragment,
      link,
      eventLinkPlacement
    });

    if (typeof resultLink === 'undefined') {
      return;
    }

    if (isValidSchema(schema)) {
      shellNavigation.push(buildRelativePath(resultLink));
    } else {
      window.open(resultLink, '_blank', 'noopener noreferrer');
    }
  };
};

export const shouldShowAction = ({ schema, key }) => {
  if (isValidSchema(schema)) {
    // show only known key to link mappings
    const deeplinkMap = getPortalDeeplinks();
    return deeplinkMap[key];
  }
  // will hide unknown schemas - besides hp-smart-app, only http and https are valid for now
  return schema?.startsWith('http');
};
