import React, { useState, useEffect, createContext } from 'react';
import { IdleTimerProvider as ExternalIdleTimerProvider } from 'react-idle-timer';
import { IDLE_TIMEOUT } from '../constants/commons';
import { UseRootContextType } from '../types/mfeProps';
import { useRootContext } from '@jarvis/react-portal-addons';

export const IdleTimerContext = createContext({ pollingEnabled: true });

export const IdleTimer = ({ children }) => {
  const [pollingEnabled, setPollingEnabled] = useState(true);
  const { mfeType, events }: UseRootContextType = useRootContext();
  const onIdleEventName = `${mfeType}:onIdle`;
  const onActiveEventName = `${mfeType}:onActive`;

  useEffect(() => {
    const enablePolling = () => {
      setPollingEnabled(true);
    };

    const disablePolling = () => {
      setPollingEnabled(false);
    };

    events.addEventListener(onIdleEventName, disablePolling);
    events.addEventListener(onActiveEventName, enablePolling);

    return () => {
      events.removeEventListener(onIdleEventName, disablePolling);
      events.removeEventListener(onActiveEventName, enablePolling);
    };
  }, [events, mfeType, onActiveEventName, onIdleEventName]);

  return (
    <IdleTimerContext.Provider value={{ pollingEnabled }}>
      <ExternalIdleTimerProvider
        timeout={IDLE_TIMEOUT}
        onActive={(event) => events.triggerEvent(onActiveEventName, event)}
        onIdle={(event) => events.triggerEvent(onIdleEventName, event)}
      >
        {children}
      </ExternalIdleTimerProvider>
    </IdleTimerContext.Provider>
  );
};
