import { JWebPromise, PluginRegistry, WhenJWebReady } from '@jarvis/jweb-core'

const getNativePlugins = async (): Promise<PluginRegistry> => {
  const response: JWebPromise = await WhenJWebReady
  console.debug('getNativePlugins > JWebPromise', response)
  const ServiceRouting = response.Plugins.ServiceRouting
  const EventService = response.Plugins.EventService
  const Device = response.Plugins.Device

  if (!ServiceRouting || !EventService || !Device) {
    throw 'JWeb is not ready'
  }
  return response.Plugins
}

export default getNativePlugins
