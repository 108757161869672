import React from 'react'
import { resolveDate } from '../../utils/shared'
import {
  resolveActionComponent,
  ActionComponentTypes
} from '../NotificationActionHelper'

export const DateAndAction = (
  notification,
  language,
  country,
  shouldShowAction,
  onClickActionFunction
) => {
  if (
    shouldShowAction &&
    notification.message.actions != null &&
    notification.message.actions.length > 0
  ) {
    return (
      <div className="notification_date_and_action">
        <div className="notification_date">
          {resolveDate(notification?.serial, language, country)}
        </div>
        {resolveActionComponent(
          notification.message.actions,
          ActionComponentTypes.INBOX_NOTIFICATION,
          shouldShowAction,
          onClickActionFunction
        )}
      </div>
    )
  }
  return resolveDate(notification?.serial, language, country)
}

export default DateAndAction
