import React, { useMemo, useCallback, useContext } from 'react';
import { JarvisInboxNotifications } from '@jarvis/react-atlas-notifications';
import { useI18n } from '@jarvis/react-portal-addons';
import { onClickActionFunction, shouldShowAction } from '../../utils/deeplinks';
import { useNotificationsPageDeepLink } from '../../hooks/useNotificationsPageDeepLink';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../../types/mfeProps';
import { IdleTimerContext } from '../../contexts/IdleTimer';
import { Container } from './styles';

const Bell = ({ baseUrlProvider }) => {
  const notificationsPageDeeplink = useNotificationsPageDeepLink();
  const { pollingEnabled } = useContext(IdleTimerContext);
  const { t } = useI18n();
  const {
    stack,
    authProvider,
    navigation,
    country,
    language
  }: UseRootContextType = useRootContext();
  const linkIDs = {
    newNotificationClick: 'new-notification-click',
    noNewNotificationClick: 'no-new-notification-click',
    remove: 'remove',
    clearAll: 'clear-all'
  };

  const GoToViewNotifications = useCallback(() => {
    return () => {
      navigation.push(notificationsPageDeeplink || '');
    };
  }, [notificationsPageDeeplink, navigation]);

  const notificationMenuOption = useMemo(
    () => [
      {
        value: 1,
        stringId: 'myAccount.headers.viewNotifications',
        OnClick: GoToViewNotifications
      }
    ],
    [GoToViewNotifications]
  );

  return (
    <Container data-testid="jarvis-inbox-notifications">
      <JarvisInboxNotifications
        country={country}
        language={language}
        authProvider={authProvider}
        baseURLProvider={baseUrlProvider}
        shouldShowAction={shouldShowAction}
        onClickActionFunction={onClickActionFunction(
          country,
          language,
          navigation,
          'view-notification'
        )}
        onError={async (error) => error} // TODO: make api call to /monitoring/logs (today this is done using the gateway).
        onRegisterEvent={async (linkID) => linkID} // TODO: the ucde-portal dispatches an event here
        eventLinkIDs={linkIDs}
        clearAllOption={{
          allow: true,
          label: t('home.topNav.notifications.clearAll')
        }}
        menuOptions={notificationMenuOption.map((item) => ({
          value: item.value,
          label: t(item.stringId),
          onClick: item.OnClick()
        }))}
        autoRefresh={pollingEnabled}
        stack={stack}
      />
    </Container>
  );
};

export default Bell;
