import React from 'react'
import {
  resolveActionComponent,
  ActionComponentTypes
} from '../../NotificationActionHelper'
import {
  Icon,
  NotificationBannerAction,
  NotificationBannerIcon,
  NotificationBannerItem,
  NotificationBannerMessage
} from './styles'
import { ErrorType } from '../../../utils/errors.tsx'

const showNotification = (data, showDebug) =>
  !(data.message.content_type == 'error' && !showDebug)
const renderNotificationBody = (notification, direction, showDebug) => {
  const {
    message: { content_type }
  } = notification

  switch (content_type) {
    case 'text/plain':
      return (
        <NotificationBannerMessage direction={direction}>
          <h3>{notification.message.title}</h3>
          <p>{notification.message.body}</p>
        </NotificationBannerMessage>
      )
    case ErrorType.GENERIC_ERROR:
      return (
        <NotificationBannerMessage direction={direction}>
          <h3>{notification.message.title}</h3>
          <p>{notification.message.body}</p>
          <p>{JSON.stringify(notification.message.extra)}</p>
        </NotificationBannerMessage>
      )
    default:
      return null
  }
}

const JarvisBannerNotificationItem = ({
  notification,
  showDebug,
  shouldShowAction,
  onClickActionFunction,
  direction
}) =>
  showNotification(notification, showDebug) && (
    <NotificationBannerItem
      id={`portal-notifications-banner-${notification.uuid}`}
      key={notification.uuid}
    >
      <NotificationBannerIcon>
        <Icon size={36} />
      </NotificationBannerIcon>
      {renderNotificationBody(notification, direction, showDebug)}
      <NotificationBannerAction>
        {resolveActionComponent(
          notification.message.actions,
          ActionComponentTypes.BANNER_NOTIFICATION,
          shouldShowAction,
          onClickActionFunction
        )}
      </NotificationBannerAction>
    </NotificationBannerItem>
  )

export default JarvisBannerNotificationItem
