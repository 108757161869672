import { useState, useEffect } from 'react'

export function useInternetConnectionChecker() {
  const [hasConnectionInternet, setHasConnectionInternet] = useState(true)

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setHasConnectionInternet(navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)
    handleOnlineStatusChange()

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  return { hasConnectionInternet }
}
