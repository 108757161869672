import { useEffect, useState } from 'react';

const useCallSuccess = (call, callback) => {
  const [hasRun, setHasRun] = useState(false);

  const {
    pending,
    success,
    data,
  } = call;

  useEffect(() => {
    if (pending && hasRun) {
      setHasRun(false);
    }
  }, [pending, hasRun]);

  useEffect(() => {
    if (hasRun) {
      return;
    }

    if (!pending && success) {
      callback(data.data);
      setHasRun(true);
    }
  }, [
    callback,
    pending,
    success,
    data,
    hasRun,
  ]);
};

export default useCallSuccess;
