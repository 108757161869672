import React from 'react';
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider';
import { JarvisAtlasInboxPage } from '@jarvis/react-atlas-notifications';
import { onClickActionFunction, shouldShowAction } from '../../utils/deeplinks';
import { Container } from './styles';
import { getUrlSearchParams } from '../../utils/routing';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../../types/mfeProps';

const AtlasInboxNotificationsPage = ({ baseUrlProvider }) => {
  const {
    authProvider,
    navigation,
    stack,
    country,
    language
  }: UseRootContextType = useRootContext();

  const { subtype, count } = getUrlSearchParams({ navigation });

  const isNumeric = (str) => (!str ? false : !isNaN(str));

  return (
    <ToastProvider>
      <Container data-testid="inbox-page-container">
        <JarvisAtlasInboxPage
          country={country.toUpperCase()}
          language={language}
          subtype={subtype || ['supplies', 'account']}
          displayMode="inbox"
          count={isNumeric(count) ? parseInt(count) : undefined}
          baseURLProvider={baseUrlProvider}
          authProvider={authProvider}
          shouldShowAction={shouldShowAction}
          onClickActionFunction={onClickActionFunction(
            country,
            language,
            navigation,
            'view-notification'
          )}
          onError={async (error) => error} // TODO: make api call to /monitoring/logs (today this is done using the gateway).
          stack={stack}
        />
      </Container>
    </ToastProvider>
  );
};

export default AtlasInboxNotificationsPage;
