import styled from 'styled-components'

export const NotificationBannerContainer = styled.div`
  display: ${(props) => (props.show ? 'none' : 'flex')};
  flex-direction: column;
`
export const LoaderContainer = styled.div`
  display: flex;
  margin: auto;
`
