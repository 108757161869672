import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { NotificationActionLinkButton } from './JarvisInboxNotification/styles'

const linkExtractRegex =
  /^(.*):\/\/([^/?#&]+)+\/?([^?#&]+)*\??([^#?]+)*#?([^/?#&]*$)/

export const extractActionParameters = (link, text) => {
  const result = linkExtractRegex.exec(link)
  const parameters = {
    schema: result && result[1],
    key: result && result[2],
    path: result && result[3],
    parameters: result && result[4],
    fragment: result && result[5],
    link: link,
    label: text
  }

  return parameters
}

const onClickWrapper = (e, params, onClickAction) => {
  e.nativeEvent &&
    e.nativeEvent.stopImmediatePropagation &&
    e.nativeEvent.stopImmediatePropagation()
  e.stopPropagation()
  e.preventDefault()
  onClickAction(params)
}

const ButtonActionComponent = ({
  appearance,
  params,
  linkKey,
  label,
  onClickAction
}) => (
  <Button
    expanded
    id={`notification-action-${linkKey.replace(/[:/.]/g, '_')}`}
    aria-label={label}
    className="action__button"
    key={label}
    onClick={(e) => {
      onClickWrapper(e, params, onClickAction)
    }}
    appearance={appearance}
  >
    {label}
  </Button>
)

const LinkButtonActionComponent = ({
  params,
  linkKey,
  label,
  onClickAction
}) => (
  <NotificationActionLinkButton
    id={`notification-action-${linkKey.replace(/[:/.]/g, '_')}`}
    className="action__link"
    key={label}
    onClick={(e) => onClickWrapper(e, params, onClickAction)}
  >
    <a href="#">{label}</a>
  </NotificationActionLinkButton>
)

const BannerActionComponent = ({ params, onClickAction }) => (
  <ButtonActionComponent
    appearance="primary"
    params={params}
    linkKey={params.key}
    label={params.label}
    onClickAction={onClickAction}
  />
)

const InboxActionComponent = ({ params, onClickAction }) => (
  <LinkButtonActionComponent
    params={params}
    linkKey={params.key}
    label={params.label}
    onClickAction={onClickAction}
  />
)

const InboxPageActionComponent = ({ params, onClickAction }) => (
  <LinkButtonActionComponent
    params={params}
    linkKey={params.key}
    label={params.label}
    onClickAction={onClickAction}
  />
)
const BANNER_NOTIFICATION_ACTION_COMPONENT = (params, onClickAction) => (
  <BannerActionComponent params={params} onClickAction={onClickAction} />
)
const INBOX_NOTIFICATION_ACTION_COMPONENT = (params, onClickAction) => (
  <InboxActionComponent params={params} onClickAction={onClickAction} />
)
const INBOX_PAGE_NOTIFICATION_ACTION_COMPONENT = (params, onClickAction) => (
  <InboxPageActionComponent params={params} onClickAction={onClickAction} />
)
export const ActionComponentTypesMap = {
  BANNER_NOTIFICATION_ACTION_COMPONENT,
  INBOX_NOTIFICATION_ACTION_COMPONENT,
  INBOX_PAGE_NOTIFICATION_ACTION_COMPONENT
}

export const resolveActionComponent = (
  actions,
  actionType,
  shouldShowAction,
  onClickActionFunction
) => {
  if (actions && actions[0]) {
    const { link, text } = actions[0]
    const params = extractActionParameters(link, text)
    if (shouldShowAction(params)) {
      const actionComponent = ActionComponentTypesMap[actionType]
      return actionComponent(params, onClickActionFunction)
    }
    return null
  }
  return null
}

const BANNER_NOTIFICATION = 'BANNER_NOTIFICATION_ACTION_COMPONENT'
const INBOX_NOTIFICATION = 'INBOX_NOTIFICATION_ACTION_COMPONENT'
const INBOX_PAGE_NOTIFICATION = 'INBOX_PAGE_NOTIFICATION_ACTION_COMPONENT'

export const ActionComponentTypes = {
  BANNER_NOTIFICATION,
  INBOX_NOTIFICATION,
  INBOX_PAGE_NOTIFICATION
}

export default resolveActionComponent
