import { GetMessagesConfig } from '@jarvis/web-stratus-client'

const prefix = '/me/messages'

const stringfyConfig = (param: GetMessagesConfig) => {
  let queryParam = ``
  if (param) {
    for (const [key, value] of Object.entries(param)) {
      queryParam +=
        queryParam.length == 0 ? `?${key}=${value}` : `&${key}=${value}`
    }
  }
  return queryParam
}

export enum ATLAS_PATH {
  GET_MESSAGE,
  SET_READ,
  DELETE
}

export const getPath = (path: ATLAS_PATH, param: any) => {
  switch (path) {
    case ATLAS_PATH.GET_MESSAGE:
      return `${prefix}${stringfyConfig(param)}`
    case ATLAS_PATH.DELETE:
      return `${prefix}/${param}`
    case ATLAS_PATH.SET_READ:
      return `${prefix}/${param}/setRead`
  }
}
