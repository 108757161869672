import { DirectionProvider } from '@veneer/core/dist/scripts/direction';
import { ThemeProvider } from '@veneer/theme/';
import React, { useMemo } from 'react';
import App from '../src/index';
import resources from '../src/assets/locale';
import projectNames from './configs/projectNames';
import { RootProvider } from '@jarvis/react-portal-addons';

export default function Root({ ...props }) {
  const { stack, properties } = props;
  const { v1 } = window.Shell;

  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    };
  }, [v1?.theme]);

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <RootProvider
            localization={v1.localization}
            resources={resources}
            shell={v1}
            authProvider={v1.authProvider}
            stack={stack}
            properties={properties}
            events={v1.events}
            navigation={v1.navigation}
            country={v1.localization.country}
            language={v1.localization.language}
            mfeType={properties?.type}
          >
            <App {...props} />
          </RootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
