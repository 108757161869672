import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@veneer/core/dist/scripts/button'
import ContextualMenu from '@veneer/core/dist/scripts/contextual_menu'
import IconBell from '@veneer/core/dist/scripts/icons/icon_bell'
import IconEllipsis from '@veneer/core/dist/scripts/icons/icon_ellipsis'
import IconX from '@veneer/core/dist/scripts/icons/icon_x'
import NotificationBadge from '@veneer/core/dist/scripts/notification_badge'
import NotificationCenter from '@veneer/core/dist/scripts/notification_center'
import OptionInterface from '@veneer/core/dist/scripts/contextual_menu'
import { InboxClient } from '@jarvis/web-stratus-client'
import { NotificationCenterContainer } from './styles'
import {
  resolveIcon,
  resolveType,
  checkProps,
  checkStack
} from '../../utils/shared'
import { getPath, ATLAS_PATH } from '../../utils/logInfo'
import { extractActionParameters } from '../NotificationActionHelper'
import { NotificationError, ErrorType } from '../../utils/errors.tsx'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
import DateAndAction from './actionAndDate'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import {
  activity,
  createSimpleUiEvent,
  screenName,
  screenPath,
  sendSimpleUiEvent,
  version
} from '../../utils/analytics'
import { Action } from '@jarvis/jweb-core'
import { withAnalytics } from '@veneer/analytics'

let notificationBellActionAuxParams = ''

export const JarvisInboxNotification = ({
  country,
  language,
  authProvider,
  mockJsonData,
  baseURLProvider,
  stack,
  refreshDelay,
  shouldShowAction,
  onClickActionFunction,
  onError,
  menuOptions,
  clearAllOption,
  onRegisterEvent,
  eventLinkIDs,
  autoRefresh,
  hideContextualMenu = false
}) => {
  const [inboxNotifications, setInboxNotifications] = useState([])
  const [showBadgeIcon, setshowBadgeIcon] = useState(false)
  const hasUnreadNotifications = (notifications) =>
    notifications && notifications.some(isNotificationUnread)
  const isNotificationRemovable = (data) => data && data.removable
  const isNotificationDismissible = (data) =>
    data && data.flags && data.flags.user_dismissible
  const isNotificationUnread = (data) =>
    data && data.flags && data.flags.read === false
  const isNotificationRead = (data) => data && data.flags && data.flags.read
  const isNotificationValid = (data) =>
    data && data.uuid && data.message && data.message.title && data.message.body
  const isNotificationClickable = (data) =>
    data && data.message && data.message.actions && data.message.actions.length
  checkProps(baseURLProvider, stack, mockJsonData)
  const inboxCheck = checkStack(baseURLProvider, stack)
  const inboxClient = new InboxClient(inboxCheck, authProvider)
  const assetsProvider = AssetsProviderFactory.create(language, country)
  const direction = assetsProvider.getDirection()
  const localizedStrings = {
    noNotifications: assetsProvider.getText('notifications.noNotifications'),
    title: assetsProvider.getText('notifications.notifications'),
    actionButton: 'Remove Notification' // This is a workaround - using the action button as remove button
  }

  const [optionsList, setOptionsList] = useState([])

  const handleClose = async () => {
    let needReload = false
    const promises =
      inboxNotifications &&
      inboxNotifications
        .filter((data) => data.id !== ErrorType.GENERIC_ERROR && !data.read)
        .map(async (notification) => {
          let startTime = new Date().getTime()
          try {
            let response = await inboxClient.setRead(notification.id)
            needReload = true
          } catch (error) {
            const host = await baseURLProvider(
              InboxClient.apiName,
              InboxClient.apiVersion
            )
            await onError({
              host,
              path: getPath(ATLAS_PATH.SET_READ, notification.id),
              method: 'POST',
              code: error.status,
              payload: error,
              latency: new Date().getTime() - startTime
            })
          }
        })
    await Promise.all(promises)
    needReload && loadInboxNotifications()
  }

  async function handleRemove(event, id) {
    event.nativeEvent &&
      event.nativeEvent.stopImmediatePropagation &&
      event.nativeEvent.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
    onRegisterEvent &&
      eventLinkIDs &&
      onRegisterEvent({ linkID: eventLinkIDs.remove })

    let startTime = new Date().getTime()
    try {
      let response = await inboxClient.delete(id)
      loadInboxNotifications()
    } catch (error) {
      const host = await baseURLProvider(
        InboxClient.apiName,
        InboxClient.apiVersion
      )
      await onError({
        host,
        path: getPath(ATLAS_PATH.DELETE, id),
        method: 'DELETE',
        code: error.status,
        payload: error,
        latency: new Date().getTime() - startTime
      })
    }
  }

  const hasRemovableNotifications = () => {
    return (
      inboxNotifications && inboxNotifications.some(isNotificationRemovable)
    )
  }

  const handleRemoveAll = async () => {
    let needReload = false
    const promises =
      inboxNotifications &&
      inboxNotifications
        .filter((data) => data.id !== ErrorType.GENERIC_ERROR && data.removable)
        .map(async (notification) => {
          let startTime = new Date().getTime()
          try {
            onRegisterEvent &&
              eventLinkIDs &&
              onRegisterEvent({ linkID: eventLinkIDs.clearAll })
            let response = await inboxClient.delete(notification.id)
            needReload = true
          } catch (error) {
            const host = await baseURLProvider(
              InboxClient.apiName,
              InboxClient.apiVersion
            )
            await onError({
              host,
              path: getPath(ATLAS_PATH.DELETE, notification.id),
              method: 'DELETE',
              code: error.status,
              payload: error,
              latency: new Date().getTime() - startTime
            })
          }
        })
    await Promise.all(promises)
    needReload && loadInboxNotifications()
  }

  const handleAction = (event, notification) => {
    const actions = notification.message.actions
    if (actions && actions[0]) {
      const { link, text } = actions[0]
      const params = extractActionParameters(link, text)
      if (shouldShowAction(params)) {
        onClickActionFunction(params)

        try {
          // Simpleui event parameters
          const eventData = {
            action: Action?.controlButtonClicked,
            screenName: screenName,
            controlName: 'NotificationMessage',
            controlDetail: notification.uuid
          }
          sendSimpleUiEvent(createSimpleUiEvent(eventData))
        } catch (error) {
          console.error('Error when trying to send simpleui event', error)
        }
      }
    }
  }

  const handleNotificationBell = () => {
    if (eventLinkIDs && onRegisterEvent) {
      const linkID = showBadgeIcon
        ? eventLinkIDs.newNotificationClick
        : eventLinkIDs.noNewNotificationClick
      onRegisterEvent({ linkID: linkID })

      try {
        // Simpleui event parameters
        const eventData = {
          action: Action?.controlButtonClicked,
          screenName: screenName,
          controlName: 'NotificationBell',
          actionAuxParams: notificationBellActionAuxParams
        }
        sendSimpleUiEvent(createSimpleUiEvent(eventData))
      } catch (error) {
        console.error('Error when trying to send simpleui event', error)
      }
    }
  }

  useEffect(() => {
    setOptionsList(buildMenuOptions())
  }, [inboxNotifications])

  const buildMenuOptions = () => {
    let menu = []
    if (clearAllOption && clearAllOption.allow) {
      menu.push({
        value: 0,
        label: clearAllOption.label,
        onClick: handleRemoveAll,
        disabled: !hasRemovableNotifications()
      })
    }
    menuOptions && menu.push(...menuOptions)
    return menu
  }

  // Required to send events with Veneer
  const ButtonNotificationSettings = withAnalytics(Button)

  const handleRightArea = () => {
    const hasValidNotification = inboxNotifications.some(
      (data) => data.id !== ErrorType.GENERIC_ERROR
    )

    if (hasValidNotification && !hideContextualMenu) {
      return (
        <ContextualMenu
          className="notification_right_area"
          onClick={(event, option) => {
            optionsList
              .find((menuItem) => option.value === menuItem.value)
              .onClick()
          }}
          options={optionsList}
        >
          <ButtonNotificationSettings
            trackId="NotificationSettings"
            appearance="tertiary"
            aria-label="Open configuration"
            leadingIcon={<IconEllipsis />}
            small
          />
        </ContextualMenu>
      )
    }
  }

  // Required to send events with Veneer
  const ButtonNotificationDelete = withAnalytics(Button)

  const resolveRemove = (notification) => {
    return (
      <ButtonNotificationDelete
        trackId="NotificationDelete"
        trackCategory={notification.uuid}
        appearance="tertiary"
        aria-label={localizedStrings.actionButton}
        leadingIcon={<IconX />}
        small
        onClick={(event) => handleRemove(event, notification.uuid)}
      />
    )
  }

  const resolveBell = (showBadge, notificationCenter) => {
    if (showBadge) {
      return (
        <NotificationBadge
          position="top-right"
          data-testid="portal-notifications-badge"
        >
          {notificationCenter}
        </NotificationBadge>
      )
    }
    return notificationCenter
  }

  const buildNotificationItems = (notifications) => {
    const validNotifications = notifications.filter(isNotificationValid)
    createNotificationSummary(validNotifications)
    return notifications.filter(isNotificationValid).map((notification) => {
      return {
        appearance: 'notification',
        id: notification.uuid,
        title: notification.message.title,
        content: notification.message.body,
        type: resolveType(notification?.configs?.level),
        removable: isNotificationDismissible(notification),
        read: isNotificationRead(notification),
        icon: resolveIcon(notification),
        date: DateAndAction(
          notification,
          language,
          country,
          shouldShowAction,
          onClickActionFunction
        ),
        action:
          isNotificationClickable(notification) &&
          isNotificationDismissible(notification)
            ? resolveRemove(notification)
            : null,
        onClickItem: isNotificationClickable(notification)
          ? (event) => {
              if (isNotificationDismissible(notification)) {
                resolveRemove(notification)
              }
              handleAction(event, notification)
            }
          : null
      }
    })
  }

  function createNotificationSummary(validNotifications) {
    try {
      const categArray = new Set()

      validNotifications.map((i) => {
        categArray.add(i.subtype)
      })

      notificationBellActionAuxParams = `totalNotifications=${validNotifications.length}`

      categArray.forEach((category) => {
        const categLength = validNotifications.filter(
          (n) => n.subtype == category
        ).length

        String
        notificationBellActionAuxParams =
          notificationBellActionAuxParams.concat(
            '&',
            category,
            '=',
            String(categLength)
          )
      })
    } catch (error) {
      console.error('error build notification', error)
    }
  }

  async function loadInboxNotifications() {
    let notificationItems
    let queryParam = {
      displayMode: 'inbox',
      subtype: ['account', 'supplies']
    }
    let startTime = new Date().getTime()
    try {
      let notifications =
        mockJsonData || (await inboxClient.getMessages(queryParam)).data
      const hasUnreadNotification = hasUnreadNotifications(notifications)
      notificationItems = buildNotificationItems(notifications)
      setshowBadgeIcon(hasUnreadNotification)
    } catch (error) {
      notificationItems = buildNotificationItems(
        NotificationError(error, country, language)
      )
      setshowBadgeIcon(false)
      const host = await baseURLProvider(
        InboxClient.apiName,
        InboxClient.apiVersion
      )
      await onError({
        host,
        path: getPath(ATLAS_PATH.GET_MESSAGE, queryParam),
        method: 'GET',
        code: error.status,
        payload: error,
        latency: new Date().getTime() - startTime
      })
    }
    setInboxNotifications(notificationItems)
  }

  let timeoutId = null
  let fetching = false

  function cancelScheduledFetch() {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  async function fetchData() {
    if (fetching) {
      return
    }
    fetching = true
    timeoutId = null
    await loadInboxNotifications()
    timeoutId = setTimeout(fetchData, refreshDelay)
    fetching = false
  }

  useEffect(() => {
    if (autoRefresh) {
      fetchData()
    } else {
      cancelScheduledFetch()
    }
    return () => {
      cancelScheduledFetch()
    }
  }, [autoRefresh])

  const NotificationList = () => {
    return resolveBell(
      showBadgeIcon,
      <NotificationCenter
        data-testid="portal-notifications-center"
        id="jarvis-inbox-notification"
        placement="bottom-end"
        i18n={localizedStrings}
        items={inboxNotifications}
        rightHeaderArea={handleRightArea()}
        onClose={() => {
          handleClose()
        }}
      >
        <Button
          type="button"
          data-testid="portal-notifications-button"
          onClick={handleNotificationBell}
          aria-label="Open Notification Center"
          leadingIcon={<IconBell />}
          appearance="tertiary"
        />
      </NotificationCenter>
    )
  }

  return (
    <JarvisAnalyticsContextProvider
      initialScreenData={{
        screenName: screenName,
        activity: activity,
        screenPath: screenPath,
        version: version
      }}
    >
      <NotificationCenterContainer
        data-testid="portal-notifications-container"
        dir={direction}
      >
        <NotificationList />
      </NotificationCenterContainer>
    </JarvisAnalyticsContextProvider>
  )
}

JarvisInboxNotification.defaultProps = {
  mockJsonData: null,
  onError: (host, path, method, code, payload, latency) => {
    // empty
  },
  refreshDelay: 300000, // 5 min
  menuOptions: [],
  autoRefresh: true
}

JarvisInboxNotification.propTypes = {
  /**
   * the user language in ISO 639-1 format
   */
  language: PropTypes.string.isRequired,
  /**
   * the user country in ISO 3166-1 alpha-2 format
   */
  country: PropTypes.string.isRequired,
  /**
   * a JarvisAuthProvider interface implementation to authenticate atlas inbox API requests
   */
  authProvider: PropTypes.object.isRequired,
  /**
   * Optional Mock Data to supply to component
   */
  mockJsonData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Base Url provider function to make api requests to
   */
  baseURLProvider: PropTypes.func,
  /**
   * The current environment stack
   */
  stack: PropTypes.number,
  /**
   * Delegate function that decides when the action component will be rendered
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  shouldShowAction: PropTypes.func.isRequired,
  /**
   * onClick Action function to be fired when action component gets clicked
   * should expect an object as parameter like the following
   * {
   *   schema,
   *   key,
   *   path,
   *   parameters,
   *   fragment,
   *   link,
   *   label,
   * }
   *
   * link parameter is the full notification action link in format: [schema]://[key][/path][?parameters][#fragment]
   */
  onClickActionFunction: PropTypes.func.isRequired,
  /**
   * Delegate function to be called when an error occurs.
   * should expect an object as parameter like the following
   * {
   *   host,
   *   path,
   *   method,
   *   code,
   *   payload,
   *   latency,
   * }
   */
  onError: PropTypes.func,
  /**
   * Milliseconds to refresh the notifications
   */
  refreshDelay: PropTypes.number,
  /**
   * Array of objects like follows
   * {
   *   value: order
   *   label: string translated
   *   onClick: handler to redirect to the correct page
   * }
   */
  menuOptions: PropTypes.arrayOf(PropTypes.shape(OptionInterface)),
  /**
   * Parameters to configure the Clear All button on the Menu Option
   */
  clearAllOption: PropTypes.shape({
    allow: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired
  }),
  /**
   * Delegate function to call for registering user events
   */
  onRegisterEvent: PropTypes.func,
  /**
   * Json with the LinkIDs for registering the events
   */
  eventLinkIDs: PropTypes.shape({
    newNotificationClick: PropTypes.string,
    noNewNotificationClick: PropTypes.string,
    remove: PropTypes.string,
    clearAll: PropTypes.string
  }),
  autoRefresh: PropTypes.bool
}

export default JarvisInboxNotification
